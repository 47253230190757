import { useEffect } from 'react';
import { TextField, Box } from '@mui/material';
import {useSafeSetState} from 'react-admin';
import _ from "lodash";
import {extensiveFilterKinds} from '../../lib/types';


const directivesToAdvancedEditorState = (directives) => {
  return directives ? JSON.stringify(directives, null, 2) : "";
}
  
const Advanced = ({directives, updateDirectives, currentEditor, setError}) => {
  const [state, setState] = useSafeSetState(directivesToAdvancedEditorState(directives));

  useEffect(() => {
    if(currentEditor != "advanced") {
      setState(directivesToAdvancedEditorState(directives))
    }
  }, [directives]);

  const validateDirectives = (newContents) => {
    if (!newContents) return [null, null];

    let json;
    try {
      json = JSON.parse(newContents);
    } catch(e) {
      return ["malformed_json", null];
    }

    if(!json.acceptable_sets) return ["no_acceptable_sets", null];
    if(!_.isArray(json.acceptable_sets)) return ["acceptable_sets_not_an_array", null];
    if(_.isEmpty(json.acceptable_sets)) return ["acceptable_sets_empty", null];

    for (let required_set_obj of json.acceptable_sets) {
    if(!required_set_obj.required_set) return ["no_required_set", null];
    if(!_.isArray(required_set_obj.required_set)) return ["required_set_not_an_array", null];
    if(_.isEmpty(required_set_obj.required_set)) return ["required_set_empty", null];

    for (let credential_spec_obj of required_set_obj.required_set) {
      if(!credential_spec_obj.credential_spec) return ["no_credential_spec", null];
      if(!_.isArray(credential_spec_obj.credential_spec)) return ["credential_spec_not_an_array", null];
      if(_.isEmpty(credential_spec_obj.credential_spec)) return ["credential_spec_empty", null];

      for (let directive of credential_spec_obj.credential_spec) {
        if(!directive.pointer) return ["no_pointer", null];
        if (/[^A-Za-z0-9 /_-]/.test(directive.pointer)) return ["invalid_pointer", null];
        if(!directive.filter) return ["no_filter", null];
        if(_.isEmpty(directive.filter)) return ["filter_empty", null];
        if(!_.isPlainObject(directive.filter)) return ["filter_must_be_an_object", null];
        let entries = Object.entries(directive.filter)[0];
        if(!entries[0] || !entries[1]) return ["invalid_filter", null];

        if(!extensiveFilterKinds.includes(entries[0])) {
          return ["invalid_filter", null];
        }
      }
    }
    }

    return [null, json];
  }

  const onChange = (e) => {
    setState(e.target.value)
    let [error, json] = validateDirectives(e.target.value);
    if (error) {
      setError(error);
      updateDirectives(null, "advanced");
    } else {
      setError(null)
      updateDirectives(json, "advanced");
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Tab') {
    event.preventDefault();  // Prevent the default tab behavior
    const start = event.target.selectionStart;
    const end = event.target.selectionEnd;

    // Insert two spaces at the current cursor position
    event.target.value = event.target.value.substring(0, start) 
                        + "  " 
                        + event.target.value.substring(end);

    // Update the cursor position after the insertion
    event.target.selectionStart = event.target.selectionEnd = start + 2;
    }
  };

  return <Box>
    <TextField
      sx={{ '& textarea': { fontFamily: 'monospace', fontSize: "0.9em", lineHeight: "1em" } }}
      onKeyDown={handleKeyDown}
      onChange={onChange}
      value={state}
      variant="outlined"
      multiline
      fullWidth
      minRows={5}
      maxRows={40}
      id="advanced-editor"
    />
  </Box>
}


export default Advanced;