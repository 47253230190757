import { useEffect } from 'react';
import { 
  Datagrid, ShowButton, TextField, Show, SimpleShowLayout, List, TopToolbar, DateField, useSafeSetState, EditButton, BooleanField
} from 'react-admin'
import {PermIdentity} from '@mui/icons-material';
import FilterTextInput from "../components/filter_textinput";
import { PaginationDefault, defaultSort } from '../components/utils';
import authProvider, { getPermissionsAndSetThem } from '../lib/auth_provider';
import requirePermissions from '../lib/permissions';
import { ArchiveResourceAsDelete } from '../components/archive_resource_action';
export const PersonIcon = PermIdentity;


const ruleFilters = [
  <FilterTextInput source="nameLike" alwaysOn />,
  <FilterTextInput source="idEq" alwaysOn />,
];


function RuleList() {
  const [permissions, setPermissions] = useSafeSetState("");
  useEffect(() => { getPermissionsAndSetThem({authProvider, setPermissions}) }, []);

  return (
    <List
      empty={false}
      sort={defaultSort}
      filters={ruleFilters}
      filter={{archivedAtIsSet: false}}
      perPage={20}
      pagination={<PaginationDefault />}
      actions={<TopToolbar/>}
    >
      <Datagrid bulkActionButtons={false}>
        <TextField source="id" />
        <TextField source="name" />
        <DateField source="createdAt" showTime={true} showDate={true}/>
        <BooleanField source="singleUseCredentials" />
        <BooleanField source="wholeCredential" />
        <ShowButton />
        {requirePermissions.canDeleteRules(permissions) && <EditButton />}
        {requirePermissions.canDeleteRules(permissions) && <ArchiveResourceAsDelete resource="Rule" variant="admin" />}
      </Datagrid>
    </List>
  );
}


function RuleShow(){
  const [permissions, setPermissions] = useSafeSetState("");
  useEffect(() => { getPermissionsAndSetThem({authProvider, setPermissions}) }, []);

  const Toolbar = () => requirePermissions.canDeleteRules(permissions) ?
  <TopToolbar>
    <EditButton />
    <ArchiveResourceAsDelete resource="Rule" variant="admin" />
  </TopToolbar>
  :
  <TopToolbar />;

  return (
    <Show actions={<Toolbar />}>
      <SimpleShowLayout>
        <TextField source="id" />
        <TextField source="name" />
        <TextField source="redirectUrl" />
        <BooleanField source="singleUseCredentials" />
        <BooleanField source="wholeCredential" />
        <DateField source="createdAt" showTime={true} showDate={true} />
        <TextField source="directives" />
      </SimpleShowLayout>
    </Show>
  );
}


export {RuleList, RuleShow, ruleFilters};
